import React from 'react';
import { PortableText } from '@portabletext/react';

import portableText from '../blog/portable-text-component';

const RawBody = ({ body }) => {
  return (
    <div className="flex w-full flex-col">
      <PortableText value={body} components={portableText as any} />
    </div>
  );
};

export default RawBody;
