import React from 'react';
import { graphql } from 'gatsby';
import Container from '@/components/common/container';
import SEO from '@/components/seo';
import Layout from '@/components/common/v5/layout';
import NewsletterForm from '@/components/common/v4/newsletter-form';
import { GatsbyImage } from 'gatsby-plugin-image';
import RawBody from '@/components/ebooks/RawBody';

export const query = graphql`
  {
    allSanityMasteringRagPage {
      nodes {
        id
        _id
        title
        subtitle
        ebookImage {
          _type
          imageAlt
          image {
            asset {
              gatsbyImageData(height: 795)
            }
          }
        }
        hubspotForm {
          formId
          portalId
          redirectLink {
            slug {
              current
            }
          }
        }
        seo {
          metaTitle
          metaDescription
          image {
            asset {
              gatsbyImageData(width: 1280)
              url
            }
          }
        }
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
const EBookPage = ({ data: queryData }) => {
  const data = queryData.allSanityMasteringRagPage.nodes[0];

  return (
    <Layout mainContentVariant="gray">
      <Container className="flex flex-col-reverse py-16 md:pb-32 md:pt-6 lg:flex-row">
        <div className="mx-auto w-full max-w-[500px] p-6 lg:mt-[100px]">
          <h1 className="text-[30px] md:text-[40px] xl:text-[56px]">
            {data.title}
          </h1>
          <p className="text-[20px] text-[#606060] sm:text-[22px]">
            {data.subtitle}
          </p>
          <div className="my-8 border-t border-solid border-t-[#868686]">
            <RawBody body={data._rawDescription} />
          </div>
          <NewsletterForm
            portalId={data.hubspotForm.portalId}
            formId={data.hubspotForm.formId}
            cssClass={'ebook-form'}
            redirectUrl={data.hubspotForm.redirectLink.slug.current}
          />
        </div>
        <section className="relative w-full flex-1">
          <GatsbyImage
            className="relative z-[1]"
            image={data.ebookImage.image.asset.gatsbyImageData}
            alt={data.ebookImage.imageAlt}
          />
          <img
            src="/ebooks/sign.svg"
            alt="Sign"
            className="absolute right-[-4%] top-[-12%] h-[440px]"
          />
        </section>
      </Container>
    </Layout>
  );
};

export default EBookPage;

export const Head = ({ data: queryData }) => {
  const seo = queryData.allSanityMasteringRagPage.nodes[0].seo;
  return (
    <SEO
      title={seo?.metaTitle}
      description={seo?.metaDescription}
      image={seo?.image?.asset?.gatsbyImageData?.images?.src}
      imageWidth={seo?.image?.asset?.gatsbyImageData?.width}
      imageHeight={seo?.image?.asset?.gatsbyImageData?.height}
    />
  );
};
